'use strict';
var $ = require('jquery');
require('./messagebox');
$(function () {
    $('.carousel-inner').find('.item').first().addClass('active');
    $('#carousel-banner').carousel();
    // add address
    var add_count = $('#add-address-btn').data('count');
    if ($("#address-template").length > 0) {
        var address_template_source = $('#address-template').html();
        var address_template = Handlebars.compile(address_template_source);
        $('#add-address-btn').click(function () {
            $('#address-list').append(address_template({id: add_count}));
            add_count += 1;
        });
    }

    $('.weight_calc').click(function () {
        var val = ($('#weight_calc_lenght').val() * $('#weight_calc_width').val() * $('#weight_calc_height').val() / 6000).toFixed(2);
        $('#weight_calc_value').html(val);
    });

    $('.price_calc').click(function () {
        $.post('/calc_price', {
            weight: $('#price_calc_weight').val(),
            methods: $('#price_calc_method').val()
        }, function (data) {
            if (data.status_code === 200) {
                $('#price_calc_value').html(data.price.toFixed(2) + ' ' + data.unit);
            }
            if (data.status_code === 400) {
                $('#price_calc_value').html('0.0');
            }
        }, 'json');
    });
    $('#address').change(function () {
        $('input[name="address_name"]').val($("#address option:selected").data('name'));
        $('input[name="address_address"]').val($("#address option:selected").data('address'));
    });
});